import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { colors, alpha, s } from '../../../style'
import { Headline } from '../Presentation/Presentation'
import { ButtonLink } from '../../button'
// import indexes from './assets/indexes'

const paths = [
  {
    d:
      'M800.82,531.965l5.75,5.581v-1.73h7.392v11.343l24.352,28.449V596.2l3.844-3.844L856.1,599v22.855L828.155,641.5v9.061h-6.04L786.7,674.966v4.971l-12.9-3.844-40.386,27.729-13.426-6.59V685.7l-54.988-24.718V609.917L737.2,568.9v-9.189l9.921-4.213v8.962Z',
    transform: 'translate(11 -103)',
    // transform: 'translate(1169.633 332.788) rotate(13)',
  },
  {
    d:
      'M976.2,437.663l31.013,37.186v8.464L1025.423,489v11.209l-18.211,12.51v11.047l-5.656.886L986.6,535.229v13.878l-10.4,5.849h-6.173L952.4,567.443l-6.415-.953L920.64,584.36l-18.682-6.823-14.134,9.26L854.2,572.484V546.021l-14.646-4.873V528.621l18.22-11.03v-7.823l34.074-25.034v-9.885l37.244-22.4h7.148v6.336h4.224Z',
    // transform: 'translate(-3761.437 -6605.662)',
    // transform: 'translate(1525.858 386.091)',
    transform: 'translate(11 -103)',
  },
  {
    d:
      'M-2548.526-13077.771v-28.511l-16.474-5.683v-6.777l16.474-10.348v-5.092l21.664-38.818,39.976,10.122v-6.234l12.635,1.368v8.986l28.157,6.668,17.951,16.239v23.886l10.771,3.59-21.542,13.307-12.883,29.143-18.586,13.706Zm143.034,1.716v-20.957l23.879-15.6,45.49,12.187v17.218l-24.061,20.794Z',
    // transform: 'translate(3418.773 7048.191) rotate(13)',
    transform: 'translate(3536.624 13455.488)',
    // transform: 'translate(11 -103)',
  },
  {
    d:
      'M1309.708,498.687c.211.634,35.48,42.027,35.48,42.027v5.7h-2.3v3.591l19.007,5.068v12.883L1338.662,591.4h-4.224v3.991l-53.13,47.515v4.124l-9.872,7.761-71.97-25.586V578.5l25.364-20.253v-8.237h10.56l54.7-44.138v-9.715h12.038V504.6Z',
    // transform: 'translate(-3736.909 -6509.923)',
    // transform: 'translate(1628.895 877.947)',
    transform: 'translate(11 -103)',
  },
  {
    d:
      'M1056.259,571.22l48.885,15.077v-5.391l10.647-4.367v13.524l46.093,15.737v-8.307l5.2-2.1,4.123,2.574v11.705l37.411,11.653,19.309,11.458-2.842,55.223-43.2,41.464-49.611-18.88-3.926,6.425-62.46-22.129-1.463-3.212-33.871-13.6V622.065Z',
    // transform: 'translate(-3782.113 -6534.767)',
    // transform: 'translate(1396.457 761.372)',
    transform: 'translate(11 -103)',
  },
  {
    d:
      'M943.039,672.567l54.772,22.444V682.827l6.661-4.89,19.007,8.285V704.6l38.99,15.6,27.845,11.486v54.487l-54.759,51.161-35.691-16.774-4.676,5.71L945.97,803.424v-6.782l-31.052-13.919v-8.209l-24.663-10.351V750.6l14.312-10.707,7.926,2.573V730.429Z',
    // transform: 'translate(-266.819 -570.118) rotate(13)',
    // transform: 'translate(1147.214 730.596) rotate(13)',
    transform: 'translate(11 -103)',
  },
  {
    d:
      'M909.9,793.124l48.915,54.747v6.412l25.557,10.405v13.48L860.664,1011.135v3.569h-5.354l-8.566-3.569L826.4,1001.1l-5.354,6.461L760.371,977.02v-63L829.256,851.2V839.815L849.6,823.755v8.565l22.129-19.273Z',
    // transform: 'translate(-3894.01 -6522.041)',
    // transform: 'translate(797.504 821.549)',
    transform: 'translate(11 -103)',
  },
]

const Visual = () => {
  const [hovered, setHovered] = useState(2)
  const {
    image: { childImageSharp },
    visual: {
      frontmatter: { visuals, title, subtitle },
    },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "visual-new.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      visual: markdownRemark(
        fileAbsolutePath: { regex: "/content/visual/visual.md/" }
      ) {
        frontmatter {
          title
          subtitle
          visuals {
            title
            subtitle
            desc
            features_isSold
            features_isClosed
            features_main {
              title
              unit
              value
            }
            #features_second {
              #title
              #unit
              #value
            #}
            features_files {
              title
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div
      css={{ position: 'relative' }}
      // onClick={() => (hovered ? setHovered(null) : setHovered(2))}
    >
      <div
        css={{
          maxWidth: 1280,
          boxSizing: 'border-box',
          margin: '0 auto',
          [s.sm]: { padding: '0 4rem' },
        }}>
        <Headline title={title} desc={subtitle} />
        <p
          css={{
            textAlign: 'center',
            fontSize: '0.75rem',
            opacity: 1,
            position: 'relative',
            left: 0,
            top: '-2rem',
          }}>
          Dotknij Willę, aby wyświetlić szczegóły
        </p>
      </div>
      <div css={{ position: 'relative', top: 0, left: 0 }}>
        <section css={sSection}>
          <Img fluid={childImageSharp.fluid} alt="apartments schema" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1920"
            height="1080"
            viewBox="0 0 1920 1080"
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // '& path': {
              //   transition: '0.3s',
              // },
            }}>
            <defs>
              <clipPath id="visual">
                <rect width="1920" height="1080" />
              </clipPath>
            </defs>
            {/* <g id="Artboard_9" clipPath="url(#clip-Artboard_9)"> */}
            {/* <g transform="translate(0.45 0.854)"> */}
            {/* <rect width="1920" height="1080" fill="#fff" /> */}
            <path
              id="dimmer"
              d="M-.45-.854h1920v1080H-.45Z"
              transform="translate(0.45 0.854)"
              fill="#35373900"
              css={{ transition: 'opacity 0.3s ease-in-out' }}
              onClick={() =>
                hovered === null ? setHovered(0) : setHovered(null)
              }
              // opacity={hovered !== null ? 0.5 : 0}
            />
            {paths.map((path, id) => (
              <path
                key={id}
                d={path.d}
                transform={path.transform}
                // onMouseEnter={() => setHovered(id)}
                // onMouseLeave={() => setHovered(null)}
                onClick={() =>
                  hovered === id ? setHovered(null) : setHovered(id)
                }
                css={[
                  // (hovered === id || hovered === null) &&
                  {
                    animation: 'fadeIn 1s ease-in-out infinite both alternate',
                    position: 'relative',
                    top: 0,
                    left: 0,
                    zIndex: 999,
                    cursor: 'pointer',
                  },
                  // hovered === id && {
                  //   animationPlayState: 'paused',
                  //   fill: colors.yellow.concat(alpha[100]),
                  // },
                  {
                    cursor: 'pointer',
                    transition: 'fill 0.3s ease-in-out',
                    '&:hover': {
                      fill:
                        hovered === null
                          ? colors.yellow.concat(alpha[50])
                          : hovered === id
                          ? colors.yellow.concat(alpha[50])
                          : colors.yellow.concat(alpha[30]),
                    },
                  },
                ]}
                fill={
                  hovered === id
                    ? colors.yellow.concat(alpha[50])
                    : hovered !== null
                    ? colors.yellow.concat(alpha[0])
                    : colors.yellow.concat(alpha[50])
                }></path>
            ))}
            {/* </g> */}
            {/* </g> */}
          </svg>
          {/* <div
            css={{
              pointerEvents: 'none',
              svg: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              },
            }}>
            {indexes}
          </div> */}
        </section>
        <div
          css={[
            sVisual,
            hovered === null
              ? {
                  opacity: 0,
                  pointerEvents: 'none',
                  transition:
                    'padding 0.3s ease-out, height 0.3s ease-out, margin 0.3s ease-out',
                  [s.xs]: {
                    // display: 'none',
                    height: 0,
                    marginBottom: '2rem',
                    padding: 0,
                  },
                }
              : {
                  opacity: 1,
                  [s.sm_down]: { padding: '2rem' },
                  [s.md]: { padding: '4rem' },
                },
            (visuals?.[hovered]?.features_isSold ||
              visuals?.[hovered]?.features_isClosed) && {
              borderRightColor: '#d64646',
            },
          ]}>
          <p className="visual-title">{visuals?.[hovered]?.title}</p>
          <p
            className="visual-subtitle"
            css={
              (visuals?.[hovered]?.features_isSold ||
                visuals?.[hovered]?.features_isClosed) && {
                color: '#d64646 !important',
              }
            }>
            {visuals?.[hovered]?.subtitle}
          </p>
          <p className="visual-desc">
            {visuals?.[hovered]?.desc}
            <br />
            <br />
            *Każdy z domów posiada poddasze nieużytkowe o powierzchni około 50
            m2 ( po podłodze) z oknami dachowymi.
          </p>
          <div css={sFeaturesMain}>
            {visuals?.[hovered]?.features_main?.map(
              ({ title, unit, value }, id) => (
                <div
                  key={id}
                  css={{ [s.xs]: { width: '50%', marginBottom: '2rem' } }}>
                  <p css={{ fontSize: '1.25rem' }}>
                    <strong>{value}</strong>
                    &nbsp;
                    <span>{unit}</span>
                  </p>
                  <p
                    css={{
                      fontSize: '0.625rem',
                      textTransform: 'uppercase',
                      opacity: 0.6,
                    }}>
                    {title}
                  </p>
                </div>
              )
            )}
          </div>
          <div css={sFeaturesSecond}>
            {visuals?.[hovered]?.features_second?.map(
              ({ title, unit, value }, id) => (
                <div key={id} css={sFeaturesSecond.item}>
                  <p
                    css={{
                      fontSize: '0.625rem',
                      textTransform: 'uppercase',
                      opacity: 0.6,
                    }}>
                    {title}
                  </p>
                  <p css={{ fontSize: '0.75rem' }}>
                    <strong>{value}</strong>
                    &nbsp;
                    <span>{unit}</span>
                  </p>
                </div>
              )
            )}
          </div>
          <div css={sFeaturesFiles}>
            {visuals?.[hovered]?.features_files?.map(({ title, file }, id) => (
              <ButtonLink
                key={id}
                external
                to={file.publicURL}
                color={colors.estetic}
                extraCss={{
                  fontSize: '0.75rem',
                  display: 'block',
                  [s.xs]: { fontSize: '0.75rem' },
                  marginBottom: '1rem',
                }}>
                {title}
              </ButtonLink>
            ))}
          </div>
          {visuals?.[hovered]?.features_isSold ||
          visuals?.[hovered]?.features_isClosed ? (
            <ButtonLink
              extraCss={{
                fontSize: '0.75rem',
                display: 'block',
                [s.xs]: { fontSize: '0.75rem' },
                cursor: 'not-allowed',
                fontWeight: 800,
              }}
              color={'#d64646'}>
              {visuals?.[hovered]?.features_isSold && 'Sprzedane'}
              {visuals?.[hovered]?.features_isClosed && 'Niedostępne'}
            </ButtonLink>
          ) : (
            <ButtonLink
              to={'/#kontakt'}
              extraCss={{
                fontSize: '0.75rem',
                // display: 'block',
                display: 'none',
                [s.xs]: { fontSize: '0.75rem' },
              }}
              color={colors.estetic}>
              Rezerwuj!
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  )
}

const sFeaturesFiles = {}

const sSection = {
  backgroundColor: 'transparent',
  width: '100vw',
  height: 'calc(900vw /16)',
  position: 'relative',
  [s.sm_down]: { margin: '7rem 0 8rem', transform: 'scale(1.5)' },
  [s.xs]: { margin: '7rem 0 8rem', transform: 'scale(2)' },
  [s.md]: { marginBottom: '10rem' },
  [s.lg]: { marginBottom: '16rem' },
}

const sVisual = {
  position: 'absolute',
  color: '#ffffff',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '4rem',
  boxSizing: 'border-box',

  [s.xl]: {
    top: '10rem',
    transform: 'none',
  },

  background: 'linear-gradient(to bottom, #626262, #353739)',
  width: 600,

  transition: 'opacity 0.3s ease-out',
  boxShadow: '4px 8px 64px rgba(0,0,0,0.3)',
  borderRight: `4px solid ${colors.estetic}`,

  [s.sm_down]: {
    position: 'static',
    top: 0,
    transform: 'none',
    left: 0,
    width: 'calc(100vw - 48px)',
    margin: '0 auto',
    marginBottom: '6rem',
  },

  '.visual-title': {
    fontSize: '1.5rem',
    fontWeight: 300,
    marginBottom: '0.5rem',
  },

  '.visual-subtitle': {
    fontSize: '0.875rem',
    fontWeight: 700,
    marginBottom: '2rem',
    color: colors.estetic,
  },

  '.visual-desc': {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.618em',
    marginBottom: '3rem',
    opacity: 0.6,
  },
}

const sFeaturesMain = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '3rem',
  [s.sm_down]: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
}

const sFeaturesSecond = {
  // display: 'flex',
  // flexWrap: 'wrap',
  // justifyContent: 'space-between',
  // alignItems: 'center',

  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  // gridGap: '3rem',
  marginBottom: '2rem',

  [s.sm_down]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  item: {
    width: 'max-content',
    // margin: 'auto',
    marginBottom: '2rem',
    // flex: '1 0 21%',
    // flexBasis: '25%',
    // maxWidth: '25%',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
}

export default Visual
